import { useEffect } from 'react'
import styles from './CreateLick.module.css'

const rotator1 = [
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/btt3sxmaumx8zhi8g5d4.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529109/gcqn5eadxqjuv5ljucna.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/v5afrpguoklsi3e9fjan.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/e2uopat4b3dfuwskflyc.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529119/kx00a8fvzc5qctz02cig.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529118/uqe5npr6ef3jxgzh7rna.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529109/gcqn5eadxqjuv5ljucna.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529118/jm1cmtb9az96ensn43mm.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529109/rcqyrstjevpqtczdbsm2.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/btt3sxmaumx8zhi8g5d4.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529109/gcqn5eadxqjuv5ljucna.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/v5afrpguoklsi3e9fjan.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/e2uopat4b3dfuwskflyc.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529119/kx00a8fvzc5qctz02cig.webp'
]

const rotator2 = [
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/btt3sxmaumx8zhi8g5d4.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529109/gcqn5eadxqjuv5ljucna.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/v5afrpguoklsi3e9fjan.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/e2uopat4b3dfuwskflyc.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529119/kx00a8fvzc5qctz02cig.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529118/uqe5npr6ef3jxgzh7rna.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529108/sztbvlwadxdwaj1lls3k.webp',
    'https://res.cloudinary.com/seimutants/image/upload/v1725529118/jm1cmtb9az96ensn43mm.webp',
]

const CreateLick = () => {
  useEffect(() => {

  }, [])

  return (
    <div style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}} className={styles.container} id='create_section'>
        <div className={styles.imgs_circular_wrpr} data-radius={700} >
            {rotator1.map((img, key) => (
                <LickImgWrpr key={key} id={key} radius={700} total={rotator1.length} img={img} />
            ))}

            <div className={styles.abst_circle}></div>
        </div>

        <div className={styles.imgs_circular_wrpr}>
            {rotator2.map((img, key) => (
                <LickImgWrpr key={key} id={key} radius={360} total={rotator2.length} img={img} />
            ))}
        </div>
        <h1 className={styles.heading}>Create your lick</h1>
        <p className={styles.description}>Upload any photo to create lick for you.</p>
        <div style={{ cursor: 'not-allowed' }} className={styles.btn_wrpr}>
            <button style={{ cursor: 'not-allowed' }} className={styles.btn}>Coming Soon</button>
        </div>
    </div>
  )
}

export default CreateLick

const LickImgWrpr = ({ id, radius, total, img }: { id: number, radius: number, total: number, img: string }) => {
    const degree = 360/total * id;

    return (
        <div className={styles.lick_img_wrpr} style={{
            transform: `translate(-50%, -50%) rotate(${degree}deg) translate(${radius}px) rotate(-${degree}deg)`
        }}>
            <img style={{ objectFit: 'cover' }} src={img} alt="" className='w-full h-full rounded-full' />
        </div>
    )
}