import { useEffect, useState } from "react";
import Loader from "./components/Loader/Loader";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Explore from "./pages/Explore/Explore";

const App = () => {
  const[loading, setLoading] = useState(false)
  const [mouth, setmouth] = useState(false)
  const [tongue, settongue] = useState(false)
  const [foot, setfoot] = useState(false)
  const [explore1, setexplore1] = useState(false)
  const [explore2, setexplore2] = useState(false)
  const [explore3, setexplore3] = useState(false)
  const [explore4, setexplore4] = useState(false)
  const [explore5, setexplore5] = useState(false)
  const [explore6, setexplore6] = useState(false)
  const [explore7, setexplore7] = useState(false)
  const [backMouth, setbackMouth] = useState(false)
  const [landingPageImage, setlandingPageImage] = useState(false)
  const [landingPageImageBackground, setlandingPageImageBackground] = useState(false)
  const [pepeImage, setpepeImage] = useState(false)
  const [pepeImageBackground, setpepeImageBackground] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true)
    }, 3000);

    return () => {
      clearTimeout(timer)
    }
  }, []);

  if(!(mouth && tongue && foot && backMouth && loading && landingPageImage && landingPageImageBackground && pepeImage && pepeImageBackground && explore1 && explore2 && explore3 && explore4 && explore5 && explore6 && explore7)) {
    return <div style={{ overflow: 'hidden' }}>
      <Loader />
      <h1 style={{ display: 'none', fontFamily: 'Ambit' }}>Test</h1>
      <h1 style={{ display: 'none', fontFamily: 'Ambit-700' }}>Test</h1>
      <h1 style={{ display: 'none', fontFamily: 'Ambit-600' }}>Test</h1>
      <h1 style={{ display: 'none', fontFamily: 'Ambit-900' }}>Test</h1>
      <img style={{ display: 'none' }} onLoad={() => {
        setmouth(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724376105/LickCoin/kqwv9hvemcts44pqbydz.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        settongue(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724376104/LickCoin/eoxontrgx9z98iaxfivc.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        setbackMouth(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724376105/LickCoin/iemzeubiqbc6ygmoynbh.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        setfoot(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724376106/LickCoin/klqxhbqx1iq9fo6gtzts.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        setlandingPageImage(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1725351965/foqvasuzpulpnahcc8kj.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        setlandingPageImageBackground(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724559162/gynaimvzitfaigpwuasc.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        setpepeImage(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724556801/ved83nhc47hgobobe9sm.webp" alt="" />
      <img style={{ display: 'none' }} onLoad={() => {
        setpepeImageBackground(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724556801/nawdz3f3yxbapsbqhb2a.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore1(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696166/vlr98k30wphr5yvhqlwf.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore2(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696164/zlydsjijrjvfbrqinhdw.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore3(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696165/juem2izqgksfh7it7g0w.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore4(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696165/gn0yvq2gw2tpgkfqloxx.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore5(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696169/hel0iiilvlsdjcn8ho5s.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore6(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696170/fsfnzmh3qanm7ogp7bia.webp" alt="" className="foot" />
      <img style={{ display: 'none' }} onLoad={() => {
        setexplore7(true)
      }} src="https://res.cloudinary.com/seimutants/image/upload/v1724696169/qr9xvfabbk05cbhhrkzt.webp" alt="" className="foot" />
    </div>
  }

  return (
    <div className="h-screen w-screen min-w-full">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/explore" element={<Explore />} />
          <Route path='*' element={<Navigate to={'/'} />}/>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;