import styles from './BuySection.module.css'
import copy from '../../assets/copy.svg'
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

const BuySection = () => {
    const [title, settitle] = useState('Copy')

    return (
        <div style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}} className={styles.container} id="buy">
            <div className={styles.buy_section}>
                <div className={styles.cntnt_wrpr}>
                    <h1 className={styles.heading}>Buy Now</h1>

                    <div className={styles.token_wrpr}>
                        <div className={styles.token_input_wrpr}>
                            <div className={`${styles.token_number}`}>{process.env.REACT_APP_CONTRACT_ADDRESS}</div>
                            <Tooltip title={title} followCursor={true}>
                                <button onClick={() => {
                                    window.navigator.clipboard.writeText((process.env.REACT_APP_CONTRACT_ADDRESS || ''))
                                    settitle('Copied')
                                    setTimeout(() => {
                                        settitle('Copy')
                                    }, 1000)
                                }} className={styles.copy_btn}>
                                    <img src={copy} alt="" />
                                </button>
                            </Tooltip>
                        </div>
                        <a target="_blank" href={process.env.REACT_APP_DEXSCREENER} className={styles.buy_btn}>Buy Token</a>
                    </div>
                </div>

                <div className={styles.wallets_wrpr}>
                    <a target="_blank" href={process.env.REACT_APP_DEXSCREENER} className={styles.wallet}>
                        <img style={{ width: '130px', height: '130px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1725533488/lt3zzspmfiecmghrvq2p.svg'} alt="" />
                    </a>
                    <a target="_blank" href={process.env.REACT_APP_PHOTON} className={styles.wallet}>
                        <img style={{ width: '130px', height: '130px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1725533490/urf4ozsvlw6ldifswzky.svg'} alt="" />
                    </a>
                    <a target="_blank" href={process.env.REACT_APP_PUMPFUN} className={styles.wallet}>
                        <img style={{ width: '100px', height: '100px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1725533283/q34c0aazle4euf9ibijn.svg'} alt="" />
                    </a>
                    <a target="_blank" href={process.env.REACT_APP_DEXTOOLS} className={styles.wallet}>
                        <img style={{ width: '100px', height: '100px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1725533491/rzrug1670vfynwezfexl.svg'} alt="" />
                    </a>
                </div>

                <div className={styles.lick_lick_tongue}>
                    <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724556801/ved83nhc47hgobobe9sm.webp'} alt="" />
                </div>
            </div>
        </div>
    )
    }

export default BuySection