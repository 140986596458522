import { Link } from 'react-router-dom'
import lick_1 from '../../assets/lick_1.png'
import lick_layer from '../../assets/lick_layer.png'
import styles from './Navbar.module.css'

const Navbar = () => {
  return (
    <nav style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}} className={styles.nav}>
        <Link to="/" style={{ cursor: 'pointer' }} className={styles.link}>
            <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/imhwtz50i1qfv4mlo258.svg'} alt="" className='w-[40px] h-[40px]' />
            <span style={{ marginTop: '2px' }} className={styles.logo_text}>LICK COIN</span>
        </Link>

        <div className={styles.button_holders}>
            <a href="#explore" className={styles.outlined_btn}>
                <img style={{ marginTop: '-1px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1724662552/nqbgtnohyvz0ldewfi0o.svg'} alt="" />
                <span style={{ marginTop: '2px' }}>Explore Licks</span>
            </a>
            <a style={{ paddingTop: '2px' }} href="#buy" className={styles.filled_btn}>Buy Token</a>
        </div>
    </nav>
  )
}

export default Navbar