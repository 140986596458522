import styles from './ExploreSection.module.css'
import { exploreSlides, slide } from '../../data/explore.slides'
import { useNavigate } from 'react-router-dom'

const ExploreSection = () => {
  const navigate = useNavigate();

  return (
    <div id="explore" style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}} className={styles.container}>
        <h1 className={styles.heading}>Explore Licks</h1>

        <div className={styles.explore_showcase}>
            {exploreSlides.map((slide: slide, key) => (
                <div
                    key={key}
                    className={styles.grid_box}
                    onClick={() => {
                        navigate('/explore', { state: { id: key } })
                    }}
                >
                    <img src={slide.mainImage} alt="" />

                    <div className={`${styles.main_text_wrpr} absolute bottom-0 left-1/2 -translate-x-1/2 p-[2px] pb-0 border-[2px] border-[#A30665] rounded-[12px] rounded-b-none`}>
                        <div className={`flex items-center justify-center w-full h-full relative rounded-[12px] rounded-b-none bg-[#A30665] px-8 py-1 pt-2 text-center text-white`}>
                            <h1 className={styles.grid_text}>Try Licking</h1>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default ExploreSection