import styles from './ExpandLoader.module.css'

const ExpandLoader = () => {
  return (
    <div className={styles.loader_cntnt}>
        <div className={styles._portion}>
            <div className={styles.mid_color}></div>
            <div className={styles.mid_color_darker}></div>
        </div>
        <div className={styles._portion} style={{flexDirection: 'row-reverse'}}>
            <div className={styles.mid_color}></div>
            <div className={styles.mid_color_darker}></div>
        </div>
    </div>
  )
}

export default ExpandLoader