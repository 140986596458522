import { useState } from 'react'
import styles from './Home.module.css'
import ExpandLoader from '../../components/ExpandLoader/ExpandLoader'
import DragFoot from '../../components/DragFoot/DragFoot'
import LandingPage from '../LandingPage/LandingPage'
import ExploreSection from '../ExploreSection/ExploreSection'
import Footer from '../../components/Footer/Footer'
import BuySection from '../../components/BuySection/BuySection'
import CreateLick from '../../components/CreateLick/CreateLick'

const Home = () => {
  const [showScreen, setShowScreen] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  
  return (
    <>
      {(new Date().getTime() > (Number(localStorage.getItem("showWebsiteTimer")) + 300000)) && showScreen && <ExpandLoader />}
      {(new Date().getTime() > (Number(localStorage.getItem("showWebsiteTimer")) + 300000)) && !showWebsite && <DragFoot setShowScreen={setShowScreen} setShowWebsite={setShowWebsite} />}
      {(!(new Date().getTime() > (Number(localStorage.getItem("showWebsiteTimer")) + 300000)) || showWebsite) && <>
        <LandingPage />
        <div className={styles.wrap_section}>

          {/* HomeLander */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724712086/sjekfyerr9cqztbigfqi.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724712081/dazc5p3rb3uyexuwbnsp.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724712085/nez1d56ebcxwgpcw7wbs.webp" />
          {/* Biden */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725549921/xdg49a35bddpi048zfgo.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724721096/a49thxbcu8zjlfiu0eww.png" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725549920/xiyx6niqfyt7muiwp4vm.webp" />
          {/* Trump */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725549921/eczorgtcthdymtknmcrq.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724721210/djeq0x2pgqbuguiqip7c.png" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725550063/whw1zqndo5rzlcm7okcu.webp" />
          {/* Elon Musk */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725550056/ouozuldoz1duklkd7fdu.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724721625/ichyh70mdprtlsfy2lrd.png" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725550063/eumjjkeahsnponp7st5x.webp" />
          {/* Ronaldo */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725550059/lv7ljl33i6qdxghucbit.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724722018/no5dupawssvkuphviwi8.png" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725549920/jqnvi6yhswpi9gdubajx.webp" />
          {/* Messi */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725550058/nbx8ubqlslj8szailg4g.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724722069/i8xdbhmdj7m9cymyzbfb.png" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725550065/au7xfhmv2zij4rwi6x0b.webp" />
          {/* Snoop Dogg */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1725549983/bxcvtwlky19j9hb5lnl6.webp" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724722106/vdeudv8poscj1lptgobf.png" />
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724717597/efckd04he72o37llk0vm.webp" />
          {/* Background */}
          <img style={{ display: 'none' }} src="https://res.cloudinary.com/seimutants/image/upload/v1724701308/exopvgmg9ves4ddnwjre.webp" />

          <ExploreSection />
          <CreateLick />
          <BuySection />
        </div>
        <Footer />
      </>}
    </>
  )
}

export default Home