import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import styles from './Explore.module.css'
import left_arrow from '../../assets/left_arrow.png'
import close_btn from '../../assets/close_btn.png'
import right_arrow from '../../assets/right_arrow.png'
import { exploreSlides, slide } from '../../data/explore.slides'
import DraggableComponent from '../../components/DraggableComponent/DraggableComponent'

const Explore = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const slideRef = useRef<HTMLDivElement>(null);
    const targets = useRef<HTMLDivElement[]>([]);

    const [count, setCount] = useState(0)
    const [allSlides, setAllSlides] = useState<slide[]>(exploreSlides)

    const slideRight = () => {
        if(!targets.current) return;

        if(count < targets.current.length - 1 && count >= 0){
            const newCount = count < targets.current.length - 1 ? count + 1 : 0;
            
            gsap.fromTo(targets.current[count], { xPercent: 0, opacity: 1, zIndex: 2 }, { duration: 1.2, xPercent: 0, opacity: 0, zIndex: 0 });
            gsap.fromTo(targets.current[newCount], { xPercent: -100, opacity: 1, zIndex: 2 }, { duration: 1.2, xPercent: 0, opacity: 1, zIndex: 1 });
            
            setCount(newCount);
        }
    }

    const slideLeft = () => {
        if(!targets.current) return

        if(count <= targets.current.length - 1 && count > 0){
            const prevCount = count > 0 ? count - 1 : targets.current.length - 1;
            
            gsap.fromTo(targets.current[count], { xPercent: 0, opacity: 1, zIndex: 2 }, { duration: 1.2, xPercent: -100, opacity: 1, zIndex: 0 });
            gsap.fromTo(targets.current[prevCount], { xPercent: 0, opacity: 0 }, { duration: 1.2, xPercent: 0, opacity: 1 });

            setCount(prevCount);
        }
    }

    // const handleSlideClick = (link: string, background: string) => {
    //     navigate(`/${link}`, { state: { background } })
    // }

    useEffect(() => {
        if(location.state?.id){
            let arr = [];
            let curr = [...exploreSlides]
            arr.push(curr[location.state.id])
            curr.splice(location.state.id, 1)
            arr.push(...curr);

            setAllSlides(arr)
        }
    }, [location])

    useGSAP(() => {
        if(targets.current && targets.current.length > 0){
            gsap.set(targets.current, { xPercent: 100,  opacity: 0 });
            gsap.set(targets.current[0], { xPercent: 0,  opacity: 1 });
        }
    }, [])

    return (
        <div className={styles.container}
            style={window.innerWidth < 900 ? {
                transform: 'rotate(90deg)',
                transformOrigin: 'bottom left',
                position: 'absolute',
                top: '-100vw',
                height: '100vw',
                width: '100vh',
            } : {}}
        >
            <div style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : (window.innerWidth > 900 && window.innerWidth < 1200) ? { zoom: `${((window.innerWidth)*0.06510416666666667) + 10}%` } : {}} className={styles.heading_abst}>
                <h1 style={window.innerWidth > 900 ? { marginTop: '12px' } : { zoom: `${(window.innerWidth)*0.04166666666 + 100}%` }}>Explore</h1>

                <button style={window.innerWidth > 900 ? {} : { marginTop: '-8px', zoom: `${(window.innerWidth)*0.08333333333 + 100}%` }} className={styles.abst_close} onClick={() => navigate('/')}>
                    <img src={close_btn} alt="" />
                </button>
            </div>

            {count > 0 && <button style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : (window.innerWidth > 900 && window.innerWidth < 1200) ? { zoom: `${((window.innerWidth)*0.06510416666666667) + 10}%` } : window.innerWidth < 900 ? { zoom: `${(window.innerWidth)*0.08333333333 + 100}%` } : {}} className={styles.left_arrow_pos} onClick={slideLeft}>
                <img src={left_arrow} alt="" />
            </button>}
            
            {count < (allSlides.length - 1) && <button style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : (window.innerWidth > 900 && window.innerWidth < 1200) ? { zoom: `${((window.innerWidth)*0.06510416666666667) + 10}%` } : window.innerWidth < 900 ? { zoom: `${(window.innerWidth)*0.08333333333 + 100}%` } : {}} className={styles.right_arrow_pos} onClick={slideRight}>
                <img src={right_arrow} alt="" />
            </button>}

            <div className={styles.slider_root} ref={slideRef} id='drag-me-slides-container'>
                {allSlides.map((slide: slide, key) => (
                    <div
                        key={key}
                        className={styles.slide_page}
                        style={{
                            background: slide?.background
                    }}
                        ref={el => (targets.current[key] = el!)}
                    >
                        <DraggableComponent
                            key={key}
                            propName={slide.propName}
                            propId={slide.propId}
                            propFace={slide.propFace}
                            propDraggable={slide.propDraggable}
                            toungue_left={slide.toungue_left}
                            tongue={slide.tongue}
                            background={slide.background}
                            id={count}
                            text={slide.text}
                        />
                    </div>
                ))}
            </div>

            <div className={styles.slides_mark_wrpr}>
                {Array(allSlides.length).fill(0, 0).map((_, key) => (
                    <div className={styles.slide_mark} key={key} style={{ opacity: key <= count ? 1: ""}} />
                ))}
            </div>
        </div>
    )
}

export default Explore